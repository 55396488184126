import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import ExtraFooterShell from 'components/flex/ExtraFooter/ExtraFooterShell'

import LayoutPage from 'components/layout/LayoutPage'
// import { HelmetProvider } from 'react-helmet-async'

interface PageProps {
  data: {
    page: {
      title?: string
      path?: string
      seo: any
      // eslint-disable-next-line
      flexContent: GatsbyTypes.WpPage_Flexcontent
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { flexContent, seo, title },
  },
  location = {},
  prefix = 'page_Flexcontent',
  pageContext,
}) => {
  if (flexContent.homeTemplate) {
    return (
      // <HelmetProvider context={{}}>
      <Layout>
        <SEO seo={seo} />
        <FlexContentHandler
          prefix={prefix}
          fields={flexContent}
          location={location}
        />
      </Layout>
      // </HelmetProvider>
    )
  }

  return (
    // <HelmetProvider context={{}}>
    <Layout>
      <SEO seo={seo} />
      <LayoutPage
        title={title || ''}
        prefix={prefix}
        flexContent={flexContent}
        location={location}
        pageContext={pageContext}
      />
      <ExtraFooterShell fields={flexContent.extrafooter} location={location} />
    </Layout>
    // </HelmetProvider>
  )
}

export const pageQuery = graphql`
  query pageById($pageId: String!) {
    page: wpPage(id: { eq: $pageId }) {
      ...generalPageFragment
    }
  }
`

export default PageTemplate
